/* [class^="col-"],
[class*="col-"] {
	float: left;
	margin: 0;
	padding: 0;
	position: relative;
	box-sizing: border-box;
} */

.col-12 {
	width: 100%;
	float: left;
	margin: 0;
	padding: 0;
	position: relative;
	box-sizing: border-box;
}

/* .col-11{
	width:91.63%;
} */
.col-10 {
	width: 83.3%;
	float: left;
	margin: 0;
	padding: 0;
	position: relative;
	box-sizing: border-box;
}

/* .col-9{
	width:75%;
} */
.col-8 {
	width: 66.64%;
	float: left;
	margin: 0;
	padding: 0;
	position: relative;
	box-sizing: border-box;
}

.col-7 {
	width: 58.31%;
	float: left;
	margin: 0;
	padding: 0;
	position: relative;
	box-sizing: border-box;
}

.col-6 {
	width: 50%;
	float: left;
	margin: 0;
	padding: 0;
	position: relative;
	box-sizing: border-box;
}

.col-5 {
	width: 41.65%;
	float: left;
	margin: 0;
	padding: 0;
	position: relative;
	box-sizing: border-box;
}

.col-4 {
	width: 33.33%;
	float: left;
	margin: 0;
	padding: 0;
	position: relative;
	box-sizing: border-box;
}

.col-3 {
	width: 25%;
	float: left;
	margin: 0;
	padding: 0;
	position: relative;
	box-sizing: border-box;
}

/* .col-2-5{
	width:20%;
} */
.col-2 {
	width: 16.66%;
	float: left;
	margin: 0;
	padding: 0;
	position: relative;
	box-sizing: border-box;
}

.col-1 {
	width: 8.33%;
	float: left;
	margin: 0;
	padding: 0;
	position: relative;
	box-sizing: border-box;
}
@media screen and (max-width: 1024px) {
	#col-6-resize.col-6 {
		width: 100%;
		display: flex;
		justify-content: center;
	}
}

@media screen and (max-width: 768px) {
	.db .col-2:not(.not_resize) {
		width: 100%;
	}

	.col-2:not(.not_resize) {
		width: 100%;
	}

	.col-1,
	.col-2,
	.col-3,
	.col-4,
	.col-5,
	.col-6,
	.col-7,
	.col-8,
	.col-10 {
		width: 100%;
	}

}
@media screen and (max-width: 600px) {

	/* .col-11:not(.not_resize), */
	.col-10:not(.not_resize),
	/* .col-9:not(.not_resize), */
	.col-8:not(.not_resize),
	.col-7:not(.not_resize),
	.col-6:not(.not_resize),
	.col-5:not(.not_resize),
	.col-4:not(.not_resize),
	.col-3:not(.not_resize),
	.col-2:not(.not_resize),
	.col-1:not(.not_resize) {
		width: 100%;
	}
}